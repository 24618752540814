import { createI18n } from 'vue-i18n'
import de_DE from './i18n/de_DE.json'
import de_AT from './i18n/de_AT.json'
import en_US from './i18n/en_US.json'
import fr_FR from './i18n/fr_FR.json'
import fr_LU from './i18n/fr_LU.json'

const i18n = createI18n({
    locale: 'de_DE',
    fallbackLocale: 'de_DE',
    warnHtmlMessage: false,
    missingWarn: false,
    legacy: false,
    messages: {
        de_DE: de_DE,
        de_AT: de_AT,
        en_US: en_US,
        fr_FR: fr_FR,
        fr_LU: fr_LU
    }
})

export default i18n

export const allowedLocales = [ 'de_DE', 'de_AT', 'en_US', 'fr_FR', 'fr_LU' ] as const
export type AllowedLocalesTypes = typeof allowedLocales[number]

export function changeLocale (locale: AllowedLocalesTypes) {
    i18n.global.locale.value = locale
}
