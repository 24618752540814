import { type App as AppType } from 'vue'
import DataReady from '@/plugins/data-ready'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import '@/font-awesome'

import i18n from '@/i18n'

export default function (app: AppType) {
    app.use(i18n)
    app.use(DataReady)
    app.component('FontAwesomeIcon', FontAwesomeIcon)
}
