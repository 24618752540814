<template>
    <footer>
        <div class="benefits bg-brand-light-grey">
            <div class="container pt-small">
                <div class="row">
                    <div class="col-12 mb-small">
                        <ul class="benefits-list">
                            <li class="text-bold text-brand-darkest-blue">
                                {{ $t('benefitslist.item1') }}
                            </li>
                            <li
                                class="text-bold text-brand-darkest-blue"
                                data-qa="footer-years-of-experience"
                            >
                                {{ $t('benefitslist.item2', {years: getYearsOfExperience}) }}
                            </li>
                            <li class="text-bold text-brand-darkest-blue">
                                {{ $t('benefitslist.item3') }}
                            </li>
                            <li class="text-bold text-brand-darkest-blue">
                                {{ $t('benefitslist.item4') }}
                            </li>
                            <li class="text-bold text-brand-darkest-blue">
                                {{ $t('benefitslist.item5') }}
                            </li>
                            <li class="text-bold text-brand-darkest-blue">
                                {{ $t('benefitslist.item6') }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="isAuthenticated && showContactFooter"
            class="vc-contact bg-brand-darkest-blue"
        >
            <div class="container">
                <div class="row">
                    <div class="col-12 pt">
                        <h2 class="vc-name text-white" data-qa="contact-footer-vc-name">{{ contact.vcName }}</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4 mb">
                        <i
                            class="crefo-brand-icon icon-phone-arrow icon-color-white"
                            aria-hidden="true"
                        />
                        <p
                            class="text-white pt-small"
                            data-qa="contact-footer-vc-phone"
                        >
                            <template v-if="isDefaultContactPhone">
                                {{ $t('contact.phone.technical_only') }}<br>
                            </template>
                            {{ contact.phone }}
                        </p>
                    </div>
                    <div class="col-12 col-md-4 mb">
                        <i
                            class="crefo-brand-icon icon-envelope icon-color-white"
                            aria-hidden="true"
                        />
                        <p class="text-white pt-small">
                            <a class="text-white" :href="'mailto:' + contact.email"
                               data-qa="contact-footer-vc-email">{{ contact.email }}</a><br>
                            <a
                                class='text-white'
                                v-bind="{ 'href': setHrefLink(nav.contactLink) }"
                                :data-qa="nav.contactLink.dataQa"
                            >
                                <span><i class="crefo-ui-icon icon-chevron-next contact-link-icon"
                                         aria-hidden="true"></i>{{ $t(nav.contactLink.translateKey) }}</span>
                            </a>
                        </p>
                    </div>
                    <div class="col-12 col-md-4 mb">
                        <i
                            class="crefo-brand-icon icon-user-open icon-color-white"
                            aria-hidden="true"
                        />
                        <p
                            class="text-white pt-small"
                            data-qa="contact-footer-vc-phone2"
                        >
                            <template v-if="isDefaultContactPhone">
                                {{ $t('contact.phone.technical_only') }}<br>
                            </template>
                            {{ contact.phone }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-menu text-white bg-brand-darkest-grey">
            <div class="container mb">
                <div class="row pt">
                    <div
                        v-if="isAuthenticated && isMitgliedsbenutzer"
                        class="col-12 col-md-6 col-xl-3"
                    >
                        <ul class="nav-footer">
                            <li
                                v-for="(item, i) in nav.column1.list1"
                                :key="i"
                            >
                                <a
                                    v-bind="{ 'href': setHrefLink(item) }"
                                    :data-qa="item.dataQa"
                                >
                                    <span>{{ $t(item.translateKey) }}</span>
                                </a>
                            </li>
                        </ul>
                        <ul class="nav-footer">
                            <li
                                v-for="(item, i) in nav.column1.list2"
                                :key="i"
                            >
                                <a
                                    v-bind="{ 'href': setHrefLink(item) }"
                                    :data-qa="item.dataQa"
                                >
                                    <span>{{ $t(item.translateKey) }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div
                        v-if="isAuthenticated && isMitgliedsbenutzer"
                        class="col-12 col-md-6 col-xl-3"
                    >
                        <ul class="nav-footer">
                            <li
                                v-for="(item, i) in nav.column2.list1"
                                :key="i"
                            >
                                <a
                                    v-bind="{ 'href': setHrefLink(item) }"
                                    :data-qa="item.dataQa"
                                >
                                    <span>{{ $t(item.translateKey) }}</span>
                                </a>
                            </li>
                        </ul>
                        <ul
                            v-show="!hasGlaubigerPortalInkassoLink && !hasErrorLoadingCustomInkasso"
                            class="nav-footer"
                        >
                            <li
                                v-for="(item, i) in nav.column2.list2"
                                :key="i"
                            >
                                <a
                                    v-bind="{ 'href': setHrefLink(item) }"
                                    :data-qa="item.dataQa"
                                >
                                    <span>{{ $t(item.translateKey) }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-6 col-xl-3">
                        <div :class="{'offset-md-3 offset-sm-0 offset-0': isAuthenticated}">
                            <ul
                                v-if="isAuthenticated && isMitgliedsbenutzer"
                                class="nav-footer"
                            >
                                <li
                                    v-for="(item, i) in nav.column3.list1"
                                    :key="i"
                                >
                                    <a
                                        v-bind="{ 'href': setHrefLink(item) }"
                                        :data-qa="item.dataQa"
                                    >
                                        <span>{{ $t(item.translateKey) }}</span>
                                    </a>
                                </li>
                            </ul>
                            <ul class="nav-footer">
                                <li
                                    v-for="(item, i) in nav.column3.list2"
                                    :key="i"
                                >
                                    <a
                                        v-bind="{ 'href': setHrefLink(item) }"
                                        :data-qa="item.dataQa"
                                    >
                                        <span>{{ $t(item.translateKey) }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-3">
                        <h3 class="footer__headline" data-qa="ce-header-23">{{ $t('memberof') }}</h3>
                        <a
                            href="http://www.credit-manager.de/"
                            class="footer__logo-link"
                            target="_blank"
                        >
                            <img
                                :src="'/typo3conf/ext/crefo_portal/Resources/Public/img/footer-logo-bvcm.svg'"
                                width="80"
                                height="31"
                                alt="BvCM"
                            >
                        </a>
                        <a
                            v-if="isNotAustrian"
                            href="http://www.inkasso.de/"
                            class="footer__logo-link"
                            target="_blank"
                        >
                            <img
                                :src="'/typo3conf/ext/crefo_portal/Resources/Public/img/footer-logo-bdiu.svg'"
                                width="80"
                                height="24"
                                alt="BDIU"
                            >
                        </a>
                    </div>
                </div>
            </div>
            <div class="container copyright">
                <div class="row">
                    <div class="col-12 pt-small">
                        <p class="mb-small">&copy; {{ getCurrentYear }} Verband der Vereine Creditreform e.V.</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script lang="ts">
import * as api from '../services'
import axios from 'axios'

interface FooterNavigation {
    column1: FooterNavigationColumn
    column2: FooterNavigationColumn
    column3: FooterNavigationColumn
}

interface FooterNavigationColumn {
    list1: FooterNavigationEntry[]
    list2: FooterNavigationEntry[]
}

interface FooterNavigationEntry {
    translateKey: string
    dataQa: string
    isRouterLink?: boolean
    href: { de: string, at?: string, en: string, fr: string, lu: string }
}

export default {
    name: 'CrefoDefaultFooter',
    data() {
        return {
            isAuthenticated: false,
            showContactFooter: false,
            contacts: [],
            contactToLoad: 'ALLGEMEIN',
            contact: {
                vcName: null as string | null,
                email: null as string | null,
                phone: null as string | null
            },
            nav: {
                contactLink: {
                    translateKey: 'menu.column3.list1.item2',
                    dataQa: 'footer-link-contact',
                    href: {
                        de: '/kontakt/',
                        en: '/en/contact/',
                        fr: '/fr/contact/',
                        lu: '/fr-lu/contact/'
                    }
                },
                column1: {
                    list1: [
                        {
                            translateKey: 'menu.column1.list1.item1',
                            dataQa: 'footer-link-1',
                            href: {
                                de: '/',
                                at: '/de-at/',
                                en: '/en/',
                                fr: '/fr/',
                                lu: '/fr-lu'
                            }
                        },
                        {
                            translateKey: 'menu.column1.list1.item2',
                            dataQa: 'footer-link-4',
                            href: {
                                de: '/portal/posteingang',
                                en: '/portal/posteingang',
                                fr: '/portal/posteingang',
                                lu: '/portal/posteingang'
                            }
                        },
                        {
                            translateKey: 'menu.column1.list1.item3',
                            dataQa: 'footer-link-5',
                            href: {
                                de: '/vorgaenge/',
                                en: '/en/files/',
                                fr: '/fr/operations/',
                                lu: '/fr-lu/operations/'
                            }
                        },
                        {
                            translateKey: 'menu.column1.list1.item4',
                            dataQa: 'footer-link-9',
                            href: {
                                de: '/mein-profil/',
                                en: '/en/my-profile/',
                                fr: '/fr/mon-profil/',
                                lu: '/fr-lu/mon-profil/'
                            }
                        }
                    ],
                    list2: [
                        {
                            translateKey: 'menu.column1.list2.item1',
                            dataQa: 'footer-link-11',
                            href: {
                                de: '/ueber-creditreform/',
                                en: '/en/about-creditreform/',
                                fr: '/fr/a-propos-de-creditreform/',
                                lu: '/fr-lu/a-propos-de-creditreform/'
                            }
                        }
                    ]
                },
                column2: {
                    list1: [
                        {
                            translateKey: 'menu.column2.list1.item1',
                            dataQa: 'footer-link-27',
                            href: {
                                de: '/bonitaetsauskunft/bonitaet-einer-firma-pruefen/',
                                en: '/en/business-reports/business-reports/',
                                fr: '/fr/solvabilite/verifier-la-solvabilite-dune-entreprise/',
                                lu: '/fr-lu/solvabilite/verifier-la-solvabilite-dune-entreprise/'
                            }
                        },
                        {
                            translateKey: 'menu.column2.list1.item2',
                            dataQa: 'footer-link-201',
                            href: {
                                de: '/bonitaetsauskunft/bonitaet-einer-privatperson-pruefen/',
                                at: '/de-at/bonitaetsauskunft/bonitaet-einer-privatperson-aus-deutschland-pruefen/',
                                en: '/en/business-reports/reports-on-private-persons/',
                                fr: '/fr/solvabilite/verifier-la-solvabilite-dun-particulier/',
                                lu: '/fr-lu/solvabilite/verifier-la-solvabilite-dun-particulier/'

                            }
                        },
                        {
                            translateKey: 'menu.column2.list1.item4',
                            dataQa: 'footer-link-142',
                            href: {
                                de: '/bonitaetsauskunft/bestellung-einer-wirtschaftsauskunft/',
                                en: '/en/business-reports/ordering-of-business-reports/',
                                fr: '/fr/solvabilite/commande-dun-rapport-commercial/',
                                lu: '/fr-lu/solvabilite/commande-dun-rapport-commercial/'
                            }
                        },
                        {
                            translateKey: 'menu.column2.list1.item3',
                            dataQa: 'footer-link-29',
                            href: {
                                de: '/bonitaetsauskunft/watchlist-unternehmen/',
                                en: '/en/business-reports/watchlist/',
                                fr: '/fr/solvabilite/watchlist-entreprises/',
                                lu: '/fr-lu/solvabilite/watchlist-entreprises/'
                            }
                        }
                    ],
                    list2: [
                        {
                            translateKey: 'menu.column2.list2.item1',
                            dataQa: 'footer-link-116',
                            href: {
                                de: '/inkasso/inkasso-mit-creditreform/',
                                en: '/en/debt-collection/debt-collection-with-creditreform/',
                                fr: '/fr/recouvrement/recouvrement-avec-creditreform/',
                                lu: '/fr-lu/recouvrement/recouvrement-avec-creditreform/'
                            }
                        },
                        {
                            translateKey: 'menu.column2.list2.item2',
                            dataQa: 'footer-link-109',
                            href: {
                                de: '/inkasso/inkassoauftrag-per-upload/',
                                en: '/en/debt-collection/upload-for-debt-collection/',
                                fr: '/fr/recouvrement/recouvrement-par-telechargement/',
                                lu: '/fr-lu/recouvrement/recouvrement-par-telechargement/'
                            }
                        },
                        {
                            translateKey: 'menu.column2.list2.item3',
                            dataQa: 'footer-link-64',
                            href: {
                                de: '/inkasso/inkassoauftrag-per-formular/',
                                en: '/en/debt-collection/fill-out-forms-for-debt-collection/',
                                fr: '/fr/recouvrement/recouvrement-par-formulaire/',
                                lu: '/fr-lu/recouvrement/recouvrement-par-formulaire/'
                            }
                        }
                    ]
                },
                column3: {
                    list1: [
                        {
                            translateKey: 'menu.column3.list1.item1',
                            dataQa: 'footer-link-92',
                            href: {
                                de: '/hilfe/',
                                en: '/en/help/',
                                fr: '/fr/aide/',
                                lu: '/fr-lu/aide/'
                            }
                        },
                        {
                            translateKey: 'menu.column3.list1.item2',
                            dataQa: 'footer-link-13',
                            href: {
                                de: '/kontakt/',
                                en: '/en/contact/',
                                fr: '/fr/contact/',
                                lu: '/fr-lu/contact/'
                            }
                        }
                    ],
                    list2: [
                        {
                            translateKey: 'menu.column3.list2.item1',
                            dataQa: 'footer-link-93',
                            href: {
                                de: '/impressum/',
                                en: '/en/legal-notice/',
                                fr: '/fr/impressum/',
                                lu: '/fr-lu/impressum/'
                            }
                        },
                        {
                            translateKey: 'menu.column3.list2.item2',
                            dataQa: 'footer-link-95',
                            href: {
                                de: '/datenschutz/',
                                en: '/en/data-protection/',
                                fr: '/fr/protection-des-donnees-aupres-de-creditreform/',
                                lu: '/fr-lu/protection-des-donnees-aupres-de-creditreform/'
                            }
                        },
                        {
                            translateKey: 'menu.column3.list2.item3',
                            dataQa: 'footer-link-96',
                            href: {
                                de: '/nutzungshinweise/',
                                en: '/en/disclaimer/',
                                fr: '/fr/instructions-dutilisation/',
                                lu: '/fr-lu/instructions-dutilisation/'
                            }
                        },
                        {
                            translateKey: 'menu.column3.list2.item4',
                            dataQa: 'footer-link-188',
                            href: {
                                de: '/eu-dsgvo/',
                                en: '/en/eu-dsgvo/',
                                fr: '/fr/eu-dsgvo/',
                                lu: '/fr-lu/eu-dsgvo/'
                            }
                        },
                        {
                            translateKey: 'menu.column3.list2.item5',
                            dataQa: 'footer-link-usercentrics',
                            href: {
                                de: 'javascript:UC_UI.showSecondLayer()',
                                en: 'javascript:UC_UI.showSecondLayer()',
                                fr: 'javascript:UC_UI.showSecondLayer()',
                                lu: 'javascript:UC_UI.showSecondLayer()'
                            }
                        },
                        {
                            translateKey: 'menu.column3.list2.item6',
                            dataQa: 'footer-link-dsgvo-datacopy',
                            href: {
                                de: 'https://www.creditreform.de/loesungen/bonitaet-risikobewertung/bonitaet-unternehmen/dsgvo-datenkopie',
                                en: 'https://www.creditreform.de/loesungen/bonitaet-risikobewertung/bonitaet-unternehmen/dsgvo-datenkopie',
                                fr: 'https://www.creditreform.de/loesungen/bonitaet-risikobewertung/bonitaet-unternehmen/dsgvo-datenkopie',
                                lu: 'https://www.creditreform.de/loesungen/bonitaet-risikobewertung/bonitaet-unternehmen/dsgvo-datenkopie'
                            }
                        }
                    ]
                }
            } as FooterNavigation,
            hasGlaubigerPortalInkassoLink: true,
            hasErrorLoadingCustomInkasso: false
        }
    },
    computed: {
        setHrefLink() {
            return (item: FooterNavigationEntry) => {
                if (this.$i18n.locale === 'de_DE') {
                    return item.href.de
                } else if (this.$i18n.locale === 'de_AT') {
                    return (item.href.at ? item.href.at : '/de-at' + item.href.de)
                } else if (this.$i18n.locale === 'en_US') {
                    return item.href.en
                } else if (this.$i18n.locale === 'fr_FR') {
                    return item.href.fr
                } else if (this.$i18n.locale === 'fr_LU') {
                    return item.href.lu
                }
            }
        },
        getCurrentYear() {
            return new Date().getFullYear()
        },
        getYearsOfExperience() {
            const baseExperience = 142
            const yearOfBaseExperience = 2021
            const currentDate = new Date()
            const yearsElapsed = Math.max(currentDate.getFullYear() - yearOfBaseExperience, 0)
            if (currentDate.getMonth() < 7 || (currentDate.getMonth() === 7 && currentDate.getDate() < 9)) {
                return baseExperience + yearsElapsed - 1
            } else {
                return baseExperience + yearsElapsed
            }
        },
        isNotAustrian() {
            return this.$i18n.locale !== 'de_AT'
        },
        isMitgliedsbenutzer() {
            return !!window.keycloak?.tokenParsed?.cr_mapping
        },
        isDefaultContactPhone() {
            return this.contact?.phone === '+49 2131 109-300'
        }
    },
    mounted() {
        window.addEventListener('loggedIn', this.onLoggedIn, false)

        window.addEventListener('loggedOut', () => {
            this.isAuthenticated = false
        }, false)

        window.addEventListener('keycloakTokenUpdated', () => {
            // Update the request interceptor on keycloak token updates
            console.log('keycloakTokenUpdated')

            axios.interceptors.request.use(
                async config => {
                    if (!window?.keycloak?.token) return config

                    if (typeof window.keycloak?.tokenParsed?.exp === 'number' && typeof window.keycloak.timeSkew === 'number') {
                        let tokenExpires = Math.round(window.keycloak.tokenParsed.exp + window.keycloak.timeSkew - new Date().getTime() / 1000)
                        if (tokenExpires < 10) {
                            await window.updateKeycloakToken()
                        }
                    }
                    config.headers.Authorization = `Bearer ${window.keycloak.token}`

                    delete config.headers['X-Act-For-Member-Id']
                    if (sessionStorage.getItem('actForMemberId') !== null) {
                        config.headers['X-Act-For-Member-Id'] = sessionStorage.getItem('actForMemberId')
                    }

                    return config
                },
                error => Promise.reject(error)
            )
        }, false)

        if (window.keycloak?.token) {
            this.onLoggedIn()
        }

        window.addEventListener('setContactByType', (e) => {
            this.contactToLoad = e.detail.type
            this.setContactByType(e.detail.type)
        }, false)
    },
    methods: {
        onLoggedIn() {
            this.isAuthenticated = true

            if (localStorage.getItem('vue-token') !== null) {
                // Add a request interceptor
                axios.interceptors.request.use(
                    config => {
                        config.headers.Authorization = 'Bearer ' + localStorage.getItem('vue-token')
                        return config
                    },
                    error => {
                        Promise.reject(error)
                    }
                )
            }

            setTimeout(() => {
                if (this.isMitgliedsbenutzer) {
                    api.getVCContact()
                        .then(response => {
                            if (response.data && response.data.name) {
                                this.contact.vcName = response.data.name
                                this.contacts = response.data.contacts || []
                                this.setContactByType(this.contactToLoad)
                                this.showContactFooter = true
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })

                    axios.get('/portal/base-layout/assets/env.json')
                        .then(response => {
                            if (response.status === 200) {
                                let $env = response.data
                                let userClz = window.keycloak?.tokenParsed?.cr_membernumber?.substring(0, 3)
                                console.log('[Inkasso Menü Footer] User CLZ: ', userClz)

                                const parseGlaeubigerPortalMapping = (clz: number) => {
                                    let mapping = {} as Record<number, string>
                                    try {
                                        mapping = JSON.parse(atob($env.GLAUBIGER_PORTAL_LINK__CLZ_MAPPING))
                                        console.log('[Inkasso Menü Footer] CLZ Mapping Gläubigerportal Inkasso Links: ', mapping)
                                    } catch (e) {
                                        console.error(e)
                                    }

                                    if (typeof mapping[clz] === 'string' && mapping[clz].length) {
                                        console.log('[Inkasso Menü Footer] Überschreibe Standard-Menüeintrag. Gläubigerportal Inkasso Link für CLZ gefunden: ', mapping[clz])
                                        this.hasGlaubigerPortalInkassoLink = true
                                    } else {
                                        console.log('[Inkasso Menü Footer] Kein Gläubigerportal Inkasso Link für CLZ gefunden. Standard-Menü (CTI) wird angezeigt.')
                                    }
                                }

                                const parseIkarosMapping = (clz: number) => {
                                    let mapping = {} as Record<number, string>
                                    try {
                                        mapping = JSON.parse(atob($env.IKAROS_CLZS))
                                        console.log('[Inkasso Menü Footer] CLZ Mapping Ikaros Inkasso: ', mapping)

                                    } catch (e) {
                                        console.error(e)
                                    }

                                    if (typeof mapping[clz] === 'string' && mapping[clz].length) {
                                        console.log('[Inkasso Menü Footer] Ikaros Konfiguration für CLZ gefunden. Startdatum: ', (new Date(Number(mapping[clz]))).toString())

                                        if (Number(mapping[clz]) < Date.now()) {
                                            console.log('[Inkasso Menü Footer] Überschreibe Standard-Menüeintrag. Ikaros Konfiguration aktiv.')
                                            this.nav.column2.list2 = [
                                                {
                                                    translateKey: 'menu.column2.list2.item1',
                                                    dataQa: 'footer-link-116-ikaors',
                                                    isRouterLink: true,
                                                    href: {
                                                        de: '/portal/inkasso',
                                                        en: '/portal/inkasso',
                                                        fr: '/portal/inkasso',
                                                        lu: '/portal/inkasso'
                                                    }
                                                },
                                                {
                                                    translateKey: 'menu.column2.list2.item2',
                                                    dataQa: 'footer-link-109-ikaors',
                                                    isRouterLink: true,
                                                    href: {
                                                        de: '/portal/inkasso/inkassoauftrag-per-upload',
                                                        en: '/portal/inkasso/inkassoauftrag-per-upload',
                                                        fr: '/portal/inkasso/inkassoauftrag-per-upload',
                                                        lu: '/portal/inkasso/inkassoauftrag-per-upload'
                                                    }
                                                },
                                                {
                                                    translateKey: 'menu.column2.list2.item3',
                                                    dataQa: 'footer-link-64-ikaors',
                                                    isRouterLink: true,
                                                    href: {
                                                        de: '/portal/inkasso/inkassoauftrag-per-formular',
                                                        en: '/portal/inkasso/inkassoauftrag-per-formular',
                                                        fr: '/portal/inkasso/inkassoauftrag-per-formular',
                                                        lu: '/portal/inkasso/inkassoauftrag-per-formular'
                                                    }
                                                }
                                            ]
                                            this.hasGlaubigerPortalInkassoLink = false
                                        } else {
                                            console.log('[Inkasso Menü Footer] Ikaros Konfiguration noch nicht aktiv. Prüfe Gläubigerportal Mapping.')
                                            parseGlaeubigerPortalMapping(clz)
                                        }
                                    } else {
                                        console.log('[Inkasso Menü Footer] Keine Ikaros Konfiguration für CLZ gefunden. Prüfe Gläubigerportal Mapping.')
                                        parseGlaeubigerPortalMapping(clz)
                                    }
                                }

                                if (!$env || typeof userClz !== 'string' || userClz.length === 0 || isNaN(Number(userClz))) {
                                    // Wenn Umgebungsvariablen nicht geladen oder die Benutzer CLZ nicht ermittelt werden kann,
                                    // wird das CTI Inkasso als Standard im Menü angezeigt.
                                    console.log('[Inkasso Menü Footer] Fehler beim Laden CLZ-spezifischer Inkasso Links. Standard-Menü (CTI) wird angezeigt.')
                                    return false
                                } else {
                                    if ($env.FEATURE_IKAROS_ACTIVE) {
                                        console.log('[Inkasso Menü Footer] Feature "IKAROS" ist aktiv. Prüfe Ikaros Mapping.')

                                        if ($env.IKAROS_CLZS && $env.IKAROS_CLZS.length) {
                                            parseIkarosMapping(Number(userClz))
                                        } else if (
                                            typeof $env.GLAUBIGER_PORTAL_LINK__CLZ_MAPPING === 'string' &&
                                            $env.GLAUBIGER_PORTAL_LINK__CLZ_MAPPING.length
                                        ) {
                                            console.log('[Inkasso Menü Footer] Kein Ikaros Mapping gefunden. Prüfe Gläubigerportal Mapping.')
                                            parseGlaeubigerPortalMapping(Number(userClz))
                                        }
                                    } else {
                                        console.log('[Inkasso Menü Footer] Feature "IKAROS" ist nicht aktiv. Prüfe Gläubigerportal Mapping.')

                                        if (
                                            typeof $env.GLAUBIGER_PORTAL_LINK__CLZ_MAPPING === 'string' &&
                                            $env.GLAUBIGER_PORTAL_LINK__CLZ_MAPPING.length
                                        ) {
                                            parseGlaeubigerPortalMapping(Number(userClz))
                                        } else {
                                            console.log('[Inkasso Menü Footer] Kein Gläubigerportal Mapping gefunden. Standard-Menü (CTI) wird angezeigt.')
                                        }
                                    }
                                }
                            } else {
                                this.hasGlaubigerPortalInkassoLink = false
                                this.hasErrorLoadingCustomInkasso = true
                            }
                        })
                        .catch(error => {
                            console.log(error)
                            this.hasGlaubigerPortalInkassoLink = false
                            this.hasErrorLoadingCustomInkasso = true
                        })

                } else {
                    this.contact = {
                        vcName: this.$t('vc.name.default'),
                        email: 'mc@creditreform.de',
                        phone: '+49 2131 109-300'
                    }
                    this.showContactFooter = true
                    this.hasGlaubigerPortalInkassoLink = false
                }
            }, 750)
        },
        setContactByType(contactType) {
            if (!this.contacts.length) {
                return
            }
            const contact = this.contacts.filter((contact) => {
                return contact.contactCategory === contactType
            })[0]
            if (contact) {
                this.contact.email = contact.email
                this.contact.phone = contact.phone
            } else {
                // fallback to first found contact
                this.contact.email = this.contacts[0].email
                this.contact.phone = this.contacts[0].phone
            }
        }
    }
}

</script>

<style scoped lang="less">
.footer__headline {
    color: #fff;
    font-weight: 700;
    font-size: 15px;
    line-height: 26px;
    text-transform: uppercase;
    margin-top: 0;
}

.footer__logo-link {
    display: block;
    margin-bottom: 30px;
}

.nav-footer {
    padding-left: 0;
    margin-bottom: 30px;
    list-style: none;

    & > li {
        position: relative;
        display: block;
        margin-bottom: 3px;

        & > a {
            line-height: 26px;
            position: relative;
            display: block;
            color: #fff;
            padding: 0;

            &:hover {
                color: #009ee2;
            }
        }
    }
}

footer .vc-contact {
    text-align: center;

    i {
        font-size: 42px;
    }
}

footer .footer-menu {
    .copyright {
        text-align: center;

        .col-12 {
            border-top: 1px solid #7F7F7F;
        }
    }
}

@media (max-width: 768px) {
    footer .benefits {
        display: none
    }
}

footer .benefits {
    text-align: center;

    .benefits-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: table;
        width: 100%;

        li {
            display: table-cell;
            padding: 0 2rem;
            position: relative;
            border-right: 2px solid transparent;
            font-size: 14px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
                border-right: none;
            }
        }
    }
}

.contact-link-icon {
    font-size: 17px !important;
    margin-right: 2px;
    position: relative;
    top: 2px;
}
</style>
