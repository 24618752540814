import axios from 'axios'

axios.defaults.headers.common = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0'
}

/**
* Sends a GET request to receive VC contact data for the logged-in user.
*/
export const getVCContact = () =>
    axios.get<VC>('/cbra/vc/forUser')
