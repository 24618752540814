<template>
    <crefo-default-footer />
</template>

<script lang="ts">
    import CrefoDefaultFooter from './components/CrefoDefaultFooter.vue'
    import { allowedLocales, AllowedLocalesTypes, changeLocale } from '@/i18n'

    export default {
        components: { CrefoDefaultFooter },
        created () {
            const logPackageName = () => {
                if (import.meta.env.MODE !== 'test') {
                    console.log(`SCS ${__APP_NAME__} ${__APP_VERSION__}`)
                }
            }

            logPackageName()
        },
        mounted () {
            window.addEventListener('languageChanged', this.setLanguage, false)
            this.setLanguage()
        },
        beforeUnmount () {
            window.removeEventListener('languageChanged', this.setLanguage)
        },
        methods: {
            setLanguage () {
                let clientLanguage: AllowedLocalesTypes = 'de_DE'

                if (document.cookie.indexOf('CAS_PREFERRED_LANGUAGE') !== -1) {
                    let langCode = document.cookie.split('CAS_PREFERRED_LANGUAGE=')[1].split(';')[0]
                    if (langCode && langCode.length && allowedLocales.includes(langCode as AllowedLocalesTypes)) {
                        clientLanguage = langCode as AllowedLocalesTypes
                    }
                }

                changeLocale(clientLanguage)
            }
        }
    }
</script>
