import { App } from 'vue'

export default {
    /* istanbul ignore next */
    install (app: App) {
        // Do not use this plugin in production (for better performance)
        if (window.location.href.indexOf('://meine.creditreform.de') !== -1) {
            return
        }

        app.mixin({
            beforeMount () {
                const event = new Event('data-ready-before-mount')
                window.dispatchEvent(event)
            },
            mounted () {
                const event = new Event('data-ready-mounted')
                window.dispatchEvent(event)
            }
        })
    }
}
